import React from 'react';
import logo from './Tritunas.png';

const contact = () => {
  return <div className='py-10 bg-gray-500'>
    <div className='flex flex-col md:flex-row justify-center md:justify-start text-white mx-10 md:mx-24'>
        <div className='mb-5 md:mr-10'>
            <img src={logo} className='w-20 md:w-16' alt="Logo Tritunas" />
        </div>
        <div className='text-sm'>
            <p className='font-bold mb-2 text-xl'>
                Our Info
            </p>
            <div className='flex'>
                <div className='flex flex-1 justify-between'>
                    <p className='mr-3 font-semibold'>
                        Address
                    </p>
                    <p className='mr-3 font-semibold'>
                        : 
                    </p>
                </div>
                <p className='w-[75%]'>
                    Ruko Golden Boulevard Blok S No. 5 <br></br>
                    Jl. Pahlawan Seribu, Lengkong Gudang, Serpong <br></br>
                    Tangerang Selatan, Banten, 15310
                </p>
            </div>
            <div className='flex'>
                <div className='flex flex-1 justify-between'>
                    <p className='mr-3 font-semibold'>
                        Email
                    </p>
                    <p className='mr-3 font-semibold'>
                        : 
                    </p>
                </div>
                <p className='w-[75%]'>
                    sales@tritunas.co.id
                </p>
            </div>
            <div className='flex'>
                <div className='flex flex-1 justify-between'>
                    <p className='mr-3 font-semibold'>
                        Phone
                    </p>
                    <p className='mr-3 font-semibold'>
                        : 
                    </p>
                </div>
                <p className='w-[75%]'>
                    +62 821 3892 9015
                </p>
            </div>
        </div>
    </div>
  </div>
};

export default contact;