import React from 'react';
import Background from './home-bg.jpg';

const title = () => {
  return <div className='min-h-screen relative flex justify-center items-center '>
      <img src={Background} alt="My Image" className='object-cover min-h-screen -z-50 brightness-75 absolute top-0'/>
      <div className='z-50 bg-blue-900 py-5 px-10 rounded-md'>
          <p className=' font-bold text-white text-3xl text-center font-sans md:text-5xl '>
              PT. Tritunas Putra Nusantara
          </p>
          <p className=' text-gray-400 text-center mt-5 font-mono md:text-xl'>
              Menghubungkan Kita, Menghubungkan Dunia
          </p>
      </div>
  </div>
};

export default title;