import React from 'react';
import Image from './teamwork.jpg'

const about_us = () => {
  return <div className='min-h-screen flex items-center bg-white py-7 rounded-t-3xl'>
    <div className='md:grid md:grid-cols-2 md:ml-36'>
        <div className='md:p-24 md:pr-0'>
            <img src={Image} alt="We Are Tritunas" />
        </div>
        <div className='p-8 md:flex md:flex-col md:justify-end md:pb-24 md:pr-52'>
            <div className='h-1 w-[50px] bg-[#264db0] mb-3'></div>
            <p className='font-bold text-4xl mb-10'>
                Kami adalah <span className=' text-[#264db0]'>Tritunas</span>
            </p>
            <p className='mb-5'>
                PT. Tritunas Putra Nusantara adalah perusahaan telekomunikasi yang berbasis di Tangerang Selatan, Indonesia. Perusahaan Tritunas Putra Nusantara berdedikasi untuk secara aktif berpartisipasi dalam pengembangan dan penyebaran jaringan akses broadband berbasis fiber.
            </p>
            <p>
                Dengan fokus yang kuat pada solusi akses broadband dan multi-layanan berbasis fiber, Kami bertujuan untuk meningkatkan konektivitas dan kualitas komunikasi di Indonesia.
            </p>
        </div>
    </div>
  </div>
};

export default about_us;