import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar from './components/navbar';
import Missions from './pages/home/missions';
import Title from './pages/home/title';
import About from './pages/home/aboutus';
import Contact from './pages/home/contacts';
import Customers from './pages/home/customers';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Title />
    <About />
    <Missions />
    <Customers />
    <Contact />
    <Navbar />
  </React.StrictMode>
);
reportWebVitals();
