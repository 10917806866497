import React from 'react';
import fiberstar from "./logoFFIBERSTAR.png";
import fiberhome from "./logoFIBERHOME.png";
import indosat from "./logoINDOSAT.png";
import telkomsel from "./logoTELKOMSEL.png";
import towerbersama from "./logoTOWERBERSAMA.png";
import iconnet from "./logoICONNET.png";
import iforte from "./logoIFORTE.png";
import linknet from "./logoLINKNET.png";
import xl from "./logoXL.png";

const customers = () => {
  return <div className='min-h-screen bg-white flex flex-col justify-center items-center'>
    <div className='flex flex-col items-center justify-center mb-7'>
        <p className='font-bold text-3xl md:text-4xl mb-3'>
            Mitra <span className='text-[#264db0]'>Tritunas</span>
        </p>
        <div className='h-1 w-[70px] bg-blue-800'></div>
        <p className='text-center text-lg font-semibold mt-2 p-5 rounded-md text-black'>
            Kami bekerja sama dengan perusahaan-perusahaan terbaik di Indonesia
        </p>
    </div>
    <div className='flex flex-col items-center justify-center h-full px-8'>
        <div className='grid grid-cols-2 gap-y-10 gap-x-5 md:grid-cols-5 md:max-w-[900px]'>
            <div className='md:col-span-5 col-span-2 gap-12 grid grid-cols-2 md:flex relative md:justify-center'>
            <img src={linknet} className='md:mx-auto h-[50px] ' />
            <img src={fiberhome} className='md:mx-auto h-12 w-full' />
            <img src={indosat} className='md:mx-auto h-[60px]' />
            <img src={telkomsel} className='md:mx-auto h-[60px]' />
            </div>
            <img src={iforte} className=' m-auto h-20 md:h-auto' />
            <img src={towerbersama} className=' m-auto' />
            <img src={xl} className=' m-auto h-14 md:h-[50%]' />
            <img src={iconnet} className=' m-auto' />
            <img src={fiberstar} className=' m-auto' />
            {/* <div className='hidden md:col-span-4 md:grid md:grid-cols-2 md:relative md:max-h-[120px]'>
              <img src={towerbersama} className=' h-20 block left-0' />
              <img src={xl} className='h-20' />
            </div> */}
        </div>
    </div>
  </div>
};

export default customers;